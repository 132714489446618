// Styles & Icons
import usdcIcon from '../assets/icons/logos/usdc_icon.png';

export const extractCoinData = (coin = null) => {
  if (!coin) return;
  return {
    coinSymbol: coin.coinData.symbol,
    coinAmt: Number(coin.coinData.balance),
    coinLogo: coin.coinData.icon,
    coinName: coin.coinData.name,
    hasError: !!coin.coinData.hasError,
    usd: Number(coin.coinData.balanceUsd) || null,
    walletId: coin.coinData.address,
    valid: coin.coinData.canSendFunds,
    timeStamp: coin?.coinData?.timeStamp,
  };
};

export const buildCoinDataObj = (coin) => ({
  coinData: {
    address: coin.walletId,
    balance: coin.coinAmt,
    balanceUsd: coin.coinSymbol === 'ETH' || coin.coinSymbol === 'BTC' ? coin.usd : null,
    canSendFunds: coin.valid,
    hasError: coin.hasError,
    icon: coin.coinLogo,
    name: coin.coinName,
    symbol: coin.coinSymbol,
    timeStamp: coin.timeStamp,
  },
});

export const nullCoinDataObj = (isUsdc, symbol) => ({
  coinData: {
    address: null,
    balance: '0.0',
    balanceUsd: '0.0',
    canSendFunds: false,
    icon: isUsdc ? usdcIcon.src : null,
    name: symbol,
    symbol: symbol,
    hasError: isUsdc ? false : true,
  },
});

export const toDollar = (amount) =>
  amount?.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  });

export const roundDecimal = (num) => Math.round(Number(num) * 100000000) / 100000000;

export const getWalletIcon = (connectorName) => {
  let iconName = 'wallet-icon.png';
  switch (connectorName.toLowerCase()) {
    case 'coinbase wallet':
      iconName = 'coinbase-wallet-icon.png';
      break;
    case 'browser / extension':
      iconName = 'metamask-icon.png';
      break;
    case 'walletconnect':
      iconName = 'walletconnect-icon.png';
      break;
  }

  let icon = `/images/wallets/${iconName}`;

  return icon;
};

export const getBlockExplorerLink = (symbol, txnHash) => {
  let isTestnet = window.location.hostname?.includes(".dev") || window.location.hostname?.includes("localhost");
  symbol = symbol.toLowerCase();
  switch(symbol) {
    case 'btc':
      return "https://live.blockcypher.com/btc" + (isTestnet ? "-testnet" : "") + "/tx/" + txnHash;
    default:
      return "https://" + (isTestnet ? 'goerli.' : "") + "etherscan.io/tx/" + txnHash;
  }
}