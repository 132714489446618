import React from 'react';

export const EllipsisLoader = () => {
  return (
    <div id="EllipsisLoader" className="flex align-middle gap-2">
      <div className="w-4 h-4 rounded-full bg-giveRed-500 animate-pulse" style={{ animationDelay: '0s' }}>
      </div>
      <div className="w-4 h-4 rounded-full bg-giveRed-500 animate-pulse" style={{ animationDelay: '0.25s' }}>
      </div>
      <div className="w-4 h-4 rounded-full bg-giveRed-500 animate-pulse" style={{ animationDelay: '0.50s' }}>
      </div>
      <div className="w-4 h-4 rounded-full bg-giveRed-500 animate-pulse" style={{ animationDelay: '0.75s' }}>
      </div>
    </div>
  )
}