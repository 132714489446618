import { useCallback, useEffect, useState } from 'react';
import { ArrowContainer, Popover as RtPopover } from 'react-tiny-popover';

export default function Tooltip({
  isOpen = null,
  triggers = { mouseover: true, click: true },
  content,
  arrowSize = 10,
  arrowColor = 'black',
  arrowStyle = {},
  children,
  containerClassName,
  ...props
}) {
  const [internalOpen, setInternalOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (isOpen !== null) {
      setInternalOpen(isOpen);
    }
  }, [isOpen]);

  const handleMouseEnter = useCallback(() => {
    if (isOpen === null && triggers.mouseover) {
      setInternalOpen(true);
    }
  }, [triggers.mouseover, clicked, isOpen]);
  const handleMouseOut = useCallback(() => {
    if (isOpen === null && triggers.mouseover) {
      if (!clicked) {
        setInternalOpen(false);
      }
    }
  }, [triggers.mouseover, clicked, isOpen]);

  const handleClick = useCallback(() => {
    if (isOpen === null) {
      setClicked(!clicked);
      setInternalOpen(!clicked);
    }
  }, [triggers.click, clicked, internalOpen, isOpen]);

  return (
    <RtPopover
      isOpen={internalOpen}
      containerClassName={containerClassName}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={arrowColor}
            arrowSize={arrowSize}
            arrowStyle={arrowStyle}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            {content}
          </ArrowContainer>
        );
      }}
      {...props}
    >
      <div
        className="tooltip-trigger inline"
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseOut}
        onClick={handleClick}
      >
        {children}
      </div>
    </RtPopover>
  );
}
