import React from 'react';

type Props = {
  type?: string;
  id?: string;
  className?: string;
  placeHolder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  label?: string;
  name?: string;
  isDisabled?: boolean;
};

export const InputLabel = (props: Props) => {
  return (
    <div className="flex flex-col w-full gap-1">
      <label htmlFor={props.id} className="text-sm font-bold">
        {props.label}
      </label>
      <input
        type={props.type}
        placeholder={props.placeHolder}
        id={props.id}
        className={`block w-full rounded-md border border-giveGray-300 border-solid shadow-sm focus:border-mint-600 focus:ring-mint-600 disabled:cursor-not-allowed disabled:border-giveGray-200 disabled:bg-giveGray-50 disabled:text-giveGray-500 sm:text-sm`}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
      />
    </div>
  );
};
