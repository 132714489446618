import React from 'react';

type TextareaLabelProps = {
  id?: string;
  label?: string;
  name?: string;
  cols?: number;
  rows?: number;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  defaultValue?: string;
};

export const TextareaLabel = (props: TextareaLabelProps) => {
  return (
    <div className="flex flex-col w-full gap-1">
      <label htmlFor={props.id} className="text-sm font-bold">
        {props.label}
      </label>
      <textarea
        name={props.name}
        id={props.id}
        cols={props.cols}
        rows={props.rows}
        value={props.value}
        onChange={props.onChange}
        className="block w-full rounded-md border border-giveGray-300 shadow-sm focus:border-mint-600 focus:ring-mint-600 sm:text-sm"
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};
