import React from 'react';

type SelectLabelProps = {
  id?: string;
  label?: string | boolean;
  name?: string;
  defaultValue?: string;
  children?: any;
  value?: any;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
};

export type SelectOptions = {
  value: any;
  label: string;
};

export const SelectLabel = (props: SelectLabelProps) => {
  return (
    <div className="flex flex-col w-full gap-1">
      <label htmlFor={props.id} className="text-sm font-bold">
        {props.label}
      </label>
      <select
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        className="mt-1 block w-full rounded-md border border-giveGray-300 py-2 pl-3 pr-10 text-base focus:border-mint-600 focus:outline-none focus:ring-mint-600 sm:text-sm"
      >
        {props.children}
      </select>
    </div>
  );
};
