import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';


/**
 * @param type "error" | "info"
 * @param children
 */
export default function Alert({ type = 'error', children }) {
  let As = InformationCircleIcon;
  let bgColor = 'bg-giveRed-100';
  let textColor = 'text-giveRed-800';
  let iconColor = 'text-giveRed-800';

  switch(type) {
    case 'error':
      As = ExclamationCircleIcon;
      bgColor = 'bg-giveRed-100';
      textColor = 'text-giveRed-800';
      iconColor = 'text-giveRed-800';
      break;
    case 'warning':
    case 'warn':
      As = ExclamationTriangleIcon;
      bgColor = 'bg-yellow-50';
      textColor = 'text-yellow-600';
      iconColor = 'text-yellow-400';
      break;
  }

  return (
    <div className={`rounded-md ${bgColor} p-4 mb-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <As
            className={`h-5 w-5 ${iconColor}`}
            aria-hidden="true"
          />
        </div>
        <div className={`ml-3 ${textColor}`}>
          {children}
        </div>
      </div>
    </div>
  );
}
