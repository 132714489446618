import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Styles
import styles from './breadcrumb.module.css';
//Utils
import { routes } from '../../../routes/routes';

const RouteLabelMap = Object.values(routes).reduce((acc, { path, value }) => {
  acc[path] = value;
  return acc;
}, {});

const buildCrumbs = (segments) =>
  segments.reduce((acc, cur, curIndex) => {
    const last = curIndex > 1 ? acc[curIndex - 1] : '';
    const newPath = last + '/' + cur;
    acc.push(newPath);
    return acc;
  }, []);

export default function Breadcrumb({ filterBy = 'All', productName = '' }) {
  const router = useRouter();
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    const segmentsPath = router.asPath.split('/');
    const crumbLinks = buildCrumbs([...segmentsPath, filterBy]);
    const productCrumb = {
      link: `${router.route}/${filterBy}/${productName}`,
      label: productName,
    };

    let breadcrumbsArr = [];
    crumbLinks.forEach((link) => {
      const crumb = {
        link: link,
        label: RouteLabelMap[link] ?? filterBy,
      };
      breadcrumbsArr.push(crumb);
    });

    if (productName) breadcrumbsArr.push(productCrumb);

    setCrumbs(breadcrumbsArr);
  }, [router.route]);

  return (
    <div className={styles.wrapper}>
      {crumbs.map((c, i) => {
        return (
          // Skip home route
          i !== 0 && (
            <div className={styles.crumbWrapper} key={i}>
              {i > 1 ? <div>/</div> : null}
              <div className={styles.crumb}>
                <Link href={c.link}>
                  <a>{c.label}</a>
                </Link>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}
