import styles from './styles.module.css';
import { roundDecimal, toDollar } from '../../../utils/wallet';

export default function DollarToEth({ dollar, eth }) {
  return (
    <div className={styles.pricing}>
      <div className={styles.priceUsd} data-core-qa="dollarAmount">
        {toDollar(dollar)}
      </div>
      <div className={styles.priceEth} data-core-qa="ethAmount">
        {roundDecimal(eth)} ETH
      </div>
    </div>
  );
}
