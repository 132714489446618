import React from 'react';

type Props = {
  isActive: boolean;
  onToggle: (isToggled: boolean) => void;
  size: 'md' | 'sm';
  isDisabled?: boolean;
};

export const Toggle = (props: Props) => {
  let toggle = props.isActive;

  const onToggleHandler = () => {
    toggle = !toggle;
    props.onToggle(toggle);
  };
  return (
    <button
      type="button"
      className={`${toggle ? 'bg-brand-200' : 'bg-giveGray-300'} ${
        props.isDisabled ? 'opacity-40' : 'opacity-100'
      } relative inline-flex ${
        props.size === 'sm' ? 'h-4 w-7' : 'h-6 w-11'
      } flex-shrink-0 ${
        props.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } rounded-full border-2 ${
        toggle ? 'border-brand-200' : 'border-giveGray-300'
      } transition-colors duration-200 ease-in-out`}
      role="switch"
      aria-checked="false"
      onClick={onToggleHandler}
      disabled={props.isDisabled}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${
          toggle
            ? `${props.size === 'sm' ? 'translate-x-3' : 'translate-x-5'}`
            : 'translate-x-0'
        } pointer-events-none inline-block ${
          props.size === 'sm' ? 'h-3 w-3' : 'h-5 w-5'
        } transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
      ></span>
    </button>
  );
};
