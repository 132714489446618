import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@nerdcoresdk/nerd-core-ui';

import styles from './twoFactorCode.module.css';
import { brand } from '../../../brand/brand';

function VerificationInput({
  value,
  handleChange,
  handleFocus,
  handleBlur,
  handleBackspace,
  isError,
  className,
  errorClassName,
  id,
  name,
}) {
  const MAX_LENGTH = 1;

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      handleBackspace(e);
    }
  };

  return (
    <input
      id={id}
      name={name}
      className={`${className} ${isError ? errorClassName : ''}`}
      type="text"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      maxLength={MAX_LENGTH}
      data-core-qa="mfaVerifyCodeInput"
    />
  );
}

export default function TwoFactorCode({
  slots = 6,
  handleSubmit,
  handleCancel,
  handleResend,
  isLoading,
  setError,
  error,
}) {
  const pasteRef = useRef();
  const buildContent = Array.from({ length: slots }, () => '');

  const [content, setContent] = useState(buildContent);
  const [isVisited, setIsVisited] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    error.error && setContent(buildContent);
  }, [error.error]);

  useEffect(() => {
    const count = timer;
    count > 0 && setTimeout(() => setTimer(count - 1), 1000);

    return function cleanUp() {
      clearTimeout(count);
    };
  }, [timer]);

  const handleFocus = (e) => {
    const { name } = e.target;
    setIsVisited(false);
    if (name === 'code-0') {
      error.error && setError({ error: false, message: '' });
    }
  };
  const handleBlur = useCallback(() => setIsVisited(true), []);

  const handleSplitPaste = useCallback((e) => {
    const copied = e.clipboardData.getData('text/plain');
    const arr = copied.split('');
    setContent((prevState) => prevState.map((value, index) => arr[index] || value));
  }, []);

  const isDisabled = useMemo(() => {
    return content.some((value) => value === '');
  }, [content]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const currentInput = +name.split('-')[1];

    setContent((prevState) => {
      const newState = [...prevState];
      newState[`${currentInput}`] = value;
      return newState;
    });

    if (value.length) {
      // It should not be last code input
      if (currentInput < 5) {
        // Get the next code input using it's name
        const nextInput = document.querySelector(`input[name=code-${currentInput + 1}]`);
        if (nextInput !== null) nextInput.focus(); // If found, focus the next field
      } else {
        // Blur last input on change
        const lastInput = document.querySelector(`input[name=code-5]`);
        if (lastInput !== '') lastInput.blur();
      }
    }
  };

  const handleBackspace = (e) => {
    const { name } = e.target;
    const currentInput = +name.split('-')[1];
    if (currentInput > 0) {
      const previousInput = document.querySelector(
        `input[name=code-${currentInput - 1}]`
      );
      if (previousInput !== '') {
        previousInput.focus();
      }
    }
  };

  useEffect(() => {
    const pasteContainer = pasteRef.current;
    pasteContainer.addEventListener('paste', handleSplitPaste);

    return () => pasteContainer.removeEventListener('paste', handleSplitPaste);
  }, [pasteRef, handleSplitPaste]);

  useEffect(() => {
    if (!isDisabled) {
      handleSubmitCode();
    }
  }, [handleSubmitCode, isDisabled]);

  const handleSubmitCode = useCallback(() => {
    const code = content.join('');
    handleSubmit(code);
  }, [content, handleSubmit]);

  const handleResendClick = useCallback(() => {
    handleResend();
    setTimer(30);
  }, [handleResend]);

  return (
    <div className="flex flex-col items-center">
      <div ref={pasteRef} className="flex flex-col items-center">
        <div className={styles.inputRow}>
          {content.map((value, position) => (
            <VerificationInput
              key={position}
              id={position}
              name={`code-${position}`}
              value={value}
              position={position}
              handleChange={handleChange}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              handleBackspace={handleBackspace}
              isError={(isVisited && isDisabled) || error.error}
              errorClassName={styles.error}
              className={
                'rounded-md h-auto w-8 border-solid border-giveGray-300 mr-1 px-1 py-2 text-center'
              }
            />
          ))}
        </div>
        <div className={styles.errorRow} data-core-qa="mfaVerifyCodeErrorText">
          {isVisited && isDisabled
            ? brand.pages.manageAccount.mfa.errorMessages.requiredMessage
            : ''}
        </div>
        {isLoading ? (
          <p className={styles.loading}>Verifying...</p>
        ) : (
          <div className={styles.resendRow}>
            <button
              type="button"
              className={timer ? styles.disableLinkButton : styles.linkButton}
              onClick={handleResendClick}
              data-core-qa="mfaVerifyCodeLink"
              disabled={timer || isLoading}
            >
              {brand.pages.manageAccount.mfa.verifyCode.resendCodeText}
            </button>
            <p className={styles.countDown} data-core-qa="mfaVerifyCodeCountDown">
              {' '}
              {timer
                ? `${brand.pages.manageAccount.mfa.setupMFA.codeResendText1} ${timer} ${brand.pages.manageAccount.mfa.setupMFA.codeResendText2}`
                : ''}
            </p>
          </div>
        )}
      </div>
      <div className={styles.btnRow}>
        <Button
          data-core-qa="mfaVerifyCodeButtonCancel"
          type="button"
          className={styles.btn}
          onClick={handleCancel}
          kind="outlined"
        >
          {brand.pages.manageAccount.mfa.setupMFA.cancelButtonText}
        </Button>
      </div>
    </div>
  );
}
