import React from 'react';

import { CircleExclamationSolid } from '~src/assets/icons/iconComponents';

import styles from './infoCard.module.css';
import LoadingEllipses from '~src/components/LoadingEllipses';
import Tooltip from '~src/components/Tooltip';
import InformationCircle from '@heroicons/react/24/outline/InformationCircleIcon';

export default function InfoCard({
                                   amountOne, //single number to display
                                   amountTwo, //will display amountOne / amountTwo on the card
                                   dataTag, //unique identifier for individual card data tags - string with no spaces
                                   isLoading,
                                   headingText,
                                   error,
                                   tooltipContent = null,
                                 }) {

  let content = null;
  if (error) {
    content = (<CircleExclamationSolid
      className={styles.dataErrorIcon}
      data-core-qa={`cardErrorIcon${dataTag}}`}
    />);
  } else if (isLoading) {
    content = (
      <div className='flex w-full justify-center items-center py-2'>
        <LoadingEllipses />
      </div>
    );
  } else if (amountTwo) {
    content = (
      <div className='flex flex-row font-bold text-xxl'>
        <p data-core-qa={`cardAmountOne${dataTag}`}>
          {Number(amountOne).toLocaleString()}
        </p>
        <p>/</p>
        <p data-core-qa={`cardAmountTwo${dataTag}`}>
          {Number(amountTwo).toLocaleString()}
        </p>
      </div>
    );
  } else {
    content = (
      <div
        className='flex flex-row font-bold text-xxl'
        data-core-qa={`cardAmountOne${dataTag}`}
      >
        {Number(amountOne).toLocaleString()}
      </div>
    );
  }

  let tooltip = null;
  if (tooltipContent) {
    tooltip = (
      <div>
        <Tooltip content={
          <div className='bg-black text-white px-2 py-1 rounded-md shadow-2xl'>{tooltipContent}</div>
        }><InformationCircle className='h-5 w-5' /></Tooltip>
      </div>
    );
  }

  return (
    <article
      className={error ? styles.cardError : 'flex flex-col justify-between items-start px-8 py-6 bg-white border border-solid border-giveGray-200 rounded-lg min-w-1/3 shadow-md'}
    >
      <header className='truncate text-md font-medium text-giveGray-500 flex flex-row gap-4 justify-between w-full'>
        <h2 data-core-qa={`cardHeadingText${dataTag}`}>{headingText}</h2>
        {tooltip}
      </header>
      <div className='mt-1 text-3xl font-semibold tracking-tight text-brandGray-900'>
        {content}
      </div>
    </article>
  );
}
